<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <div
      v-if="
        isGroupX &&
        showBanner &&
        urlRedirectionWarningPeriodHasBegun &&
        !urlRedirectionPeriodHasEnded
      "
      id="banner"
      style="
        z-index: 999;
        position: sticky;
        top: 0;
        width: 100%;
        height: 50px;
        padding-top: 12.5px;
      "
      class="bg-success text-white text-center py-auto"
    >
      <span
        v-if="
          urlRedirectionWarningPeriodHasBegun && !urlRedirectionPeriodHasBegun
        "
      >
        <strong>Message important</strong>: Veuillez prendre note qu'à compter
        du 11 octobre, une nouvelle adresse vous sera communiquée pour accéder à
        GroupX.
      </span>

      <span
        v-if="urlRedirectionPeriodHasBegun && !urlRedirectionPeriodHasEnded"
      >
        <strong>Message important</strong>: Veuillez prendre note que l'adresse
        pour accéder à GroupX a changé pour
        <a href="https://groupx.monicagroups.com">groupx.monicagroups.com</a>.
      </span>
      <b-button variant="outline-light" class="ml-2" size="sm" @click="showBanner = false">OK</b-button>
    </div>

    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { ref, watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { BButton } from "bootstrap-vue";
import { useWindowSize, useCssVar } from "@vueuse/core";
import dayjs from "dayjs";
import Tenant from "@/plugins/tenant";
import store from "@/store";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");
const LayoutPublic = () => import("@/layouts/public/LayoutPublic.vue");

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    LayoutPublic,
    BButton,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      if (this.$route.meta.layout === "public") return "layout-public";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    isGroupX() {
      return Tenant.resolveTenantId().toLocaleLowerCase() === "transat";
    },
    today() {
      return dayjs();
    },
    urlRedirectionWarningPeriodHasBegun() {
      const startDate = dayjs("2023-09-27", ["YYYY-MM-DD", "fr", true]);
      return this.today.isAfter(startDate);
    },
    urlRedirectionPeriodHasBegun() {
      const startDate = dayjs("2023-10-12", ["YYYY-MM-DD", "fr", true]);
      return this.today.isAfter(startDate);
    },
    urlRedirectionPeriodHasEnded() {
      const startDate = dayjs("2023-10-25", ["YYYY-MM-DD", "fr", true]);
      return this.today.isAfter(startDate);
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    let showBanner = ref(true);
    return {
      skinClasses,
      showBanner
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
</style>
