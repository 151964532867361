import {decodeAndValidate} from "@/modules/authnz/models/token";
import Roles from "@/modules/authnz/models/role";

const AUTH_STORE_NAMESPACE = 'app-auth';

const IS_AUTHENTICATED_GETTER = 'isAuthenticated';
const ACCESS_TOKEN_GETTER = 'accessToken';
const REFRESH_TOKEN_GETTER = 'refreshToken';
const AUTHENTICATED_USER_GETTER = 'authenticatedUser';

const AUTH_SUCCESS_MUTATION = 'setDataForSuccessfulLogin';
const DATA_REMOVAL_MUTATION = 'removeUserAuthData';
const ORG_SETTINGS_SUCCESS_MUTATION = 'setOrganizationSettingsForSuccessfulLogin';

const REGISTER_AUTH_DATA_ACTION = 'registerAuthenticatedUserTokenAndData';
const SET_ORG_SETTINGS_ACTION = 'setOrganizationSettings';

export default {
  namespaced: true,
  state: {
    user: { role: Roles.GUEST },
    tokens: {},
    orgSettings: {
      name: '',
      payment_enable: false,
      pcvoyages_enable: false,
      public_url_enable: false,
    }
  },
  getters: {
    [IS_AUTHENTICATED_GETTER]: state => state.tokens !== null
      && state.tokens !== undefined
      && Object.keys(state.tokens).length !== 0
      && state.tokens.accessToken.length !== 0
      && state.tokens.refreshToken.length !== 0,

    [ACCESS_TOKEN_GETTER]: state => state.tokens?.accessToken,
    [REFRESH_TOKEN_GETTER]: state => state.tokens?.refreshToken,

    [AUTHENTICATED_USER_GETTER]: state => state.user !== null
    && state.user !== undefined
    && Object.keys(state.user).length !== 0 ? state.user : null,


  },
  mutations: {
    [AUTH_SUCCESS_MUTATION](state, data) {
      state.tokens = {
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      };
      state.user = data.user;
    },
    [DATA_REMOVAL_MUTATION](state) {
      state.tokens = {};
      state.user = { role: Roles.GUEST };
    },
    [ORG_SETTINGS_SUCCESS_MUTATION](state, data) {
      state.orgSettings = {
        name: data.settings.name,
        payment_enable: data.settings.payment_enable,
        pcvoyages_enable: data.settings.pcvoyages_enable,
        request_email: data.settings.request_email,
        stripe_publishable_key: data.settings.stripe_publishable_key,
        public_url_enable: data.settings.public_url_enable,
        payment_processor: data.settings.payment_processor,
      };
    },
  },
  actions: {
    [REGISTER_AUTH_DATA_ACTION]({commit}, tokens) {
      let decodedAccessToken = decodeAndValidate(tokens.access_token);
      let role = Roles.mapRole(decodedAccessToken.groups);

      commit(AUTH_SUCCESS_MUTATION, {
        accessToken: tokens.access_token,
        refreshToken: tokens.refresh_token,
        user: {
          id: decodedAccessToken.sub,
          fullName: decodedAccessToken.name,
          role: role,
          [role.associatedResourceClaim()]: decodedAccessToken[role.associatedResourceClaim()],
        }
      });
    },
    [SET_ORG_SETTINGS_ACTION]({commit}, settings) {
      commit(ORG_SETTINGS_SUCCESS_MUTATION, settings);
    }
  },
};

export {
  AUTH_STORE_NAMESPACE,

  IS_AUTHENTICATED_GETTER,
  ACCESS_TOKEN_GETTER,
  REFRESH_TOKEN_GETTER,
  AUTHENTICATED_USER_GETTER,

  AUTH_SUCCESS_MUTATION,
  DATA_REMOVAL_MUTATION,
  ORG_SETTINGS_SUCCESS_MUTATION,

  REGISTER_AUTH_DATA_ACTION,
  SET_ORG_SETTINGS_ACTION
};
