import axios from 'axios';
import store from '@/store';
import {
  ACCESS_TOKEN_GETTER,
  AUTH_STORE_NAMESPACE,
  DATA_REMOVAL_MUTATION,
  REFRESH_TOKEN_GETTER,
  REGISTER_AUTH_DATA_ACTION
} from "@/modules/authnz/models/store";
import authenticationConfig from "@/configuration/authentication-config";
import httpClientConfig from "@/configuration/http-client-config";
import router from "@/router";
import {AUTHNZ_ROUTES_NAMES} from "@/router/authnz";
import Tenant from "@/plugins/tenant";

const tenantId = Tenant.resolveTenantId();

const httpClient = axios.create({
  baseURL: httpClientConfig.coreApiBaseUrl,
  timeout: httpClientConfig.clientTimeout,
  headers: {
    'X-TENANT-ID': tenantId
  }
});

httpClient.interceptors.request.use(
  config => {
    const accessToken = store.getters[`${AUTH_STORE_NAMESPACE}/${ACCESS_TOKEN_GETTER}`];
    if (accessToken) {
      config.headers = {
        "Authorization": `${authenticationConfig.tokenType} ${accessToken}`,
        "X-Tenant-Id": tenantId,
      }
    }
    return config;
  },
  error => Promise.reject(error),
);

function isAuthenticationRequest(originalRequest) {
  return originalRequest.url === `${authenticationConfig.authEndpoint}`
    || originalRequest.url === `${authenticationConfig.authCallback}`
    || originalRequest.url === `${authenticationConfig.logoutEndpoint}`;
}

httpClient.interceptors.response.use(
  response => response,
  async error => {
    const {config: originalRequest, response} = error;
    if (response && response.status === 401 && originalRequest.url !== `${authenticationConfig.refreshEndpoint}`) {
      if (isAuthenticationRequest(originalRequest)) {
        router.app.$log.debug('Unauthorized response detected when authenticating or signing out the current user');
        store.commit(`${AUTH_STORE_NAMESPACE}/${DATA_REMOVAL_MUTATION}`);
        return Promise.reject(error);
      } else {
        router.app.$log.debug(`Unauthorized response received for request /${originalRequest.url}, attempting re-authentication`);
        const refreshToken = store.getters[`${AUTH_STORE_NAMESPACE}/${REFRESH_TOKEN_GETTER}`];
        router.app.$log.debug('Purging any user data from the authentication store');
        store.commit(`${AUTH_STORE_NAMESPACE}/${DATA_REMOVAL_MUTATION}`);

        try {
          let tokenRenewalResponse = await httpClient.post(authenticationConfig.refreshEndpoint, {refresh_token: refreshToken});
          router.app.$log.debug('Re-authentication succeeded, re-populating authentication store with response data');
          await store.dispatch(`${AUTH_STORE_NAMESPACE}/${REGISTER_AUTH_DATA_ACTION}`, tokenRenewalResponse.data);
        } catch (error) {
          router.app.$log.error(
            `Re-authentication failed, redirecting user using route name ${AUTHNZ_ROUTES_NAMES.LOGIN}`,
            error
          );
          await router.push({name: AUTHNZ_ROUTES_NAMES.LOGIN});
          return Promise.resolve();
        }
      }
      return httpClient(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default httpClient;
